import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import * as pageStyles from './HelpTextTooltip.module.css';
import QuestionMarkIcon from './icons/QuestionMarkIcon';

export default function HelpTextTooltip({ id, children }) {
  return (
    <div className={pageStyles.helpTextIcon}>
      <QuestionMarkIcon id={id} />
      <ReactTooltip anchorSelect={`#${id}`} place="top">
        {children}
      </ReactTooltip>
    </div>
  );
}

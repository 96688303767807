import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import * as pageStyles from './MenuItemCategoryList.module.css';

const ALL_CATEGORIES = [
  'Breakfast',
  'Drinks',
  'Bowls',
  'Snacks',
  'Sandwiches',
  'Salads',
  'Proteins',
];

const CategoryItem = ({
  categoryLabel,
  selectedCatagories,
  handleCategorySelect,
}) => {
  const checked = Boolean(selectedCatagories?.includes(categoryLabel));
  return (
    <div
      className={pageStyles.category}
      style={{ cursor: checked ? 'pointer' : 'default' }}
    >
      <input
        id={`checkbox-${categoryLabel}`}
        key={`checkbox-${categoryLabel}`}
        type="checkbox"
        name="categories"
        value={categoryLabel}
        checked={checked}
        onChange={handleCategorySelect}
      />
      <label
        htmlFor={`checkbox-${categoryLabel}`}
        key={`label-${categoryLabel}`}
        style={{ cursor: checked ? 'pointer' : 'default' }}
      >{` ${categoryLabel}`}</label>
    </div>
  );
};

export default function MenuItemCategoryList({
  stagingItem,
  updateItemField,
  setResults,
}) {
  const selectedCatagories = stagingItem?.categories || [];

  const handleCategoryReorder = reorderedCategories => {
    updateItemField('categories', reorderedCategories.map(String));
  };

  const handleCategorySelect = e => {
    /*eslint-disable */
    const { categories } = stagingItem;
    try {
      categories
        ? categories?.includes(e.target.value)
          ? updateItemField(
              'categories',
              categories.filter(
                currCategory => currCategory !== e.target.value,
              ),
            )
          : updateItemField('categories', [...categories, e.target.value])
        : updateItemField('categories', [e.target.value]);
    } catch (error) {
      setResults(error);
    }
    /* eslint-enable */
  };

  return (
    <>
      <div className={pageStyles.categoryWrapper}>
        Category*
        <ReactSortable
          list={selectedCatagories}
          setList={handleCategoryReorder}
          className={pageStyles.categoryWrapper}
          ghostClass={pageStyles.sortableGhostClass}
        >
          {selectedCatagories.map(categoryLabel => (
            <CategoryItem
              key={`container-${categoryLabel}`}
              categoryLabel={categoryLabel}
              selectedCatagories={selectedCatagories}
              handleCategorySelect={handleCategorySelect}
            />
          ))}
        </ReactSortable>
        {ALL_CATEGORIES.filter(
          category => !selectedCatagories.includes(category),
        ).map(categoryLabel => (
          <CategoryItem
            key={`container-${categoryLabel}`}
            categoryLabel={categoryLabel}
            selectedCatagories={selectedCatagories}
            handleCategorySelect={handleCategorySelect}
          />
        ))}
      </div>
      <p className={pageStyles.hint}>
        Hint: You can reorder selected categories by clicking and dragging them
        to prefered position.
      </p>
    </>
  );
}

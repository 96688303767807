import React from 'react';
import DatePicker from 'react-date-picker';
import * as styles from './DateInput.module.css';

export default function DateInput({ id, label, value, onChange, itemKey }) {
  return (
    <label htmlFor={id} className={styles.wrapper} key={`${itemKey}-label`}>
      <DatePicker
        value={value}
        onChange={onChange}
        className={styles.datePicker}
        id={id}
        format="yyyy-MM-dd"
        key={itemKey}
      />
      {label}
    </label>
  );
}

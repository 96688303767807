import React from 'react';
import * as styles from './Input.module.css';

export default function Input({
  className = '',
  name = '',
  handleChange = () => {},
  currentValue = false,
  title = false,
  min = '1',
  type,
  readonly = false,
  keyName,
  publishedValue,
  internalUse = false,
  publicLaunch = false,
}) {
  let updatedTitle;
  const classes = [];
  if (internalUse) {
    updatedTitle = `${title}*`;
  }
  if (publicLaunch) {
    updatedTitle = `${title}^`;
  }
  if ((currentValue && currentValue !== '') || readonly) {
    classes.push(styles.inputFilled);
  } else {
    classes.push(styles.input);
  }

  if (className.length) {
    classes.push(...className.split(' '));
  }

  const classesString = classes.join(' ');

  return (
    <div className={styles.label}>
      <input
        type={type}
        min={min}
        id={name}
        name={name}
        key={keyName}
        className={classesString}
        onChange={e => handleChange(e.target.value)}
        value={currentValue || ''}
        readOnly={readonly}
      />
      {title?.length ? (
        <label htmlFor={name}>
          {updatedTitle || title}
          {readonly ? ' (uneditable)' : ''}
        </label>
      ) : (
        ''
      )}
      {publishedValue ? (
        <p className={styles.publishedText}>
          Current value: <i>{publishedValue}</i>
        </p>
      ) : (
        ''
      )}
    </div>
  );
}

import React from 'react';
import { round } from 'lodash';
import Input from './Input';
import * as styles from './UnpackedIngredientsList.module.css';
import Button from './Button';

export default function UnpackedIngredientsList({
  unpackedIngredients,
  updateUnpackedIngredientsItemName,
  updateUnpackedIngredientsContentsName,
  updateUnpackedIngredientsContentsDescription,
  removeUnpackedIngredientItem,
  addNewIngredient,
  removeIngredient,
  addNewItem,
  publishedValue,
}) {
  return (
    <div className={styles.ingredientsList}>
      <p>Unpacked Ingredients</p>
      {unpackedIngredients
        .filter(item => item)
        .map(({ itemName, contents }, i) => (
          <div className={styles.ingredient}>
            <div className={styles.ingredientName}>
              <span>{`Item ${i + 1}. `}</span>
              <Input
                name={`Ingredient_Item_Name_${i}`}
                title="Item Name"
                type="text"
                handleChange={val => {
                  updateUnpackedIngredientsItemName(i, val);
                }}
                currentValue={itemName || ''}
                value={itemName || ''}
                internalUse
              />
            </div>
            <p>Contents</p>
            {contents?.map(({ name, description }, c) => (
              <div className={styles.ingredientContents}>
                <Input
                  className={styles.ingredientContents}
                  handleChange={val => {
                    updateUnpackedIngredientsContentsName(i, c, val);
                  }}
                  name={`Contents_${itemName}`}
                  type="text"
                  title="Name"
                  currentValue={name || ''}
                  value={name || ''}
                />
                <Input
                  className={styles.ingredientContents}
                  handleChange={val => {
                    updateUnpackedIngredientsContentsDescription(i, c, val);
                  }}
                  name={`Contents_${itemName}`}
                  type="text"
                  title="Description (Optional)"
                  currentValue={description || ''}
                  value={description || ''}
                />
                {unpackedIngredients.length > 1 && (
                  <Button
                    isTertiary
                    className={styles.removeButton}
                    type="button"
                    handleSubmit={() => removeUnpackedIngredientItem(i)}
                  >
                    Remove Item
                  </Button>
                )}
                <div className={styles.buttonWrapper}>
                  {c + 1 === contents.length ? (
                    <Button
                      isTertiary
                      className={styles.addItemButton}
                      type="button"
                      handleSubmit={() => addNewIngredient(i)}
                    >
                      Add Ingredient
                    </Button>
                  ) : null}

                  <Button
                    isTertiary
                    className={styles.addItemButton}
                    type="button"
                    handleSubmit={() => removeIngredient(i, c)}
                  >
                    Remove Ingredient
                  </Button>
                </div>
              </div>
            ))}

            {unpackedIngredients.length < 2 && (
              <Button
                isPrimary
                className={styles.submitButton}
                type="button"
                handleSubmit={addNewItem}
              >
                Add Item
              </Button>
            )}
          </div>
        ))}
      {publishedValue?.[0]?.itemName
        ? publishedValue.map(({ itemName, contents }) => (
            <div className={styles.publishedValueUnpackedIngredients}>
              <h5>Published UnpackedIngredients</h5>
              <p>ItemName: {itemName}</p>
              <h5>Contents: </h5>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
                {contents.map(({ name, description }) => (
                  <tr>
                    <td>{name}</td>
                    {description ? <td>Description: {description}</td> : null}
                  </tr>
                ))}
              </table>
            </div>
          ))
        : null}
    </div>
  );
}
